<template>
  <div>
      <div class=" m-2 pt-2 pb-2 bg-secondary text-light">Product Management</div>
      <div class="row">
          <div class="col">
              <div class="input-group">
                <div class="w-50"><input type="text" class="form-control form-control-sm" placeholder="Enter category" aria-label="City"></div>
                <button class="btn btn-outline-primary btn-sm ms-2 " v-on:click="listProducts">Get</button>
                <button class="btn btn-outline-primary btn-sm ms-2"  v-on:click="newProduct">New Product</button>
              </div>
          </div>
      </div>
      <div class="row">
          <div v-if="showscreen==1" class="mt-2"> 
             <div class="table-responsive">
             <table class="table table-striped table-hover caption-top">
                 <caption>Search Reasults</caption>
                 <thead>
                     <tr>
                         <td>Name</td>
                         <td>Category</td>
                         <td>ITP</td>
                         <td>GST</td>
                         <td></td>
                     </tr>
                 </thead>
                 <tbody>
                     <tr v-for="(prod) in products" v-bind:key="prod.id">
                         <td>{{prod.pname}}</td>
                         <td>{{prod.pcat}}</td>
                         <td>{{prod.mrp}}</td>
                         <td>{{prod.gst}}</td>
                         <td>
                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary" v-on:click="showDetails(prod)"><i class="bi bi-card-text"></i></button>
                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary"><i class="bi bi-pencil-square"></i></button>
                         </td>
                     </tr>
                 </tbody>
             </table>
             </div>

          </div>
          <div v-if="showscreen==2" class="mt-2">
             <div class="card">
                 <div class="card-header">
                     <h3>New Product</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Product Name</span>
                                    <input type="text" class="form-control" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Part Number</span>
                                    <input type="text" class="form-control" placeholder="part number">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Category</span>
                                    <input type="text" class="form-control" placeholder="product category">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > SGST</span>
                                    <input type="text" class="form-control" placeholder="SGST Rate">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > CGST</span>
                                    <input type="text" class="form-control" placeholder="CGST Rate">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > IGST</span>
                                    <input type="number" class="form-control" placeholder="IGST Rate">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text"> Description</span>
                                    <textarea class="form-control" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group col-6">
                                    <span class="input-group-text" > ITP</span>
                                    <input type="number" class="form-control" placeholder="ITP">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > HSN Code</span>
                                    <input type="text" class="form-control" placeholder="HSN">
                                </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2">Save</button>
                 </div>
             </div>
          </div>
          <div v-if="showscreen==3" class="mt-2">
             <div class="card">
                 <div class="card-header">
                     <h3>Product Details</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Product Name</span>
                                    <input type="text" class="form-control" v-model="curProduct.pname" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Category</span>
                                    <input type="text" class="form-control" v-model="curProduct.pcat" placeholder="product category">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group col-6">
                                    <span class="input-group-text" > ITP</span>
                                    <input type="text" class="form-control" v-model="curProduct.mrp" placeholder="MRP">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Part Number</span>
                                    <input type="text" class="form-control" v-model="curProduct.partno" placeholder="part number">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > HSN Code</span>
                                    <input type="text" class="form-control" v-model="curProduct.hsn" placeholder="HSN">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > GST Rate</span>
                                    <input type="number" class="form-control" v-model="curProduct.gst" placeholder="GST Rate in %">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6">
                                <div class="input-group">
                                    <span class="input-group-text"> Description</span>
                                    <textarea class="form-control" rows="5" v-model="curProduct.desc"></textarea>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Selling Price</span>
                                    <input type="text" class="form-control" v-model="curProduct.price" placeholder="Default selling price">
                                </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2">Save</button>
                     <button class="ps-2 btn  btn-primary btn-sm ms-2" v-on:click="showListScreen">Close</button>
                 </div>
             </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'ProductMgmt',
  data(){
      return{
          showscreen:0,
          products:[],
          curProduct:{id:"6",pname:"HP LaserJet Pro M126nw",pcat:"PRINTER",snum:"785763",partno:"235991",hsn:"84713746",gst:"18",mrp:"21,329.00",desc:"HP LaserJet Pro M126nw Wireless Black & White Multi-Function Laserjet Printer (Wireless Direct Printing, CZ175A, Black)",price:"200"},
          curProdIdx:5,
      }
  },
  created(){
      this.products=[
          {id:"1",pname:"Inspiron 15 Laptop",pcat:"LAPTOP",partno:"icc-c783511win8",hsn:"84713010",gst:"18",mrp:"79,989.99",desc:"",price:"75,500"},
          {id:"2",pname:"Inspiron 14 Laptop 8GB",pcat:"LAPTOP",partno:"icc-c782512win8",hsn:"84713010",gst:"18",mrp:"72,489.98",desc:"",price:"71,000"},
          {id:"3",pname:"Inspiron 14 Laptop 16GB",pcat:"LAPTOP",partno:"icc-c782519win8",hsn:"84713010",gst:"18",mrp:"66,989.99",desc:"",price:"62,400"},
          {id:"4",pname:"Inspiron 15 3000 laptop",pcat:"LAPTOP",partno:"d560509win9s",hsn:"84713010",gst:"18",mrp:"46,489.98",desc:"",price:"44,800"},
          {id:"5",pname:"HP 34W78PA",pcat:"LAPTOP",partno:"15s-fr2006TU",hsn:"84713010",gst:"18",mrp:"46,499",desc:"11th Generation Intel® Core™ i3 processor",price:"45,500"},
          {id:"6",pname:"HP LaserJet Pro M126nw",pcat:"PRINTER",partno:"235991",hsn:"84713746",gst:"18",mrp:"21,329.00",desc:"HP LaserJet Pro M126nw Wireless Black & White Multi-Function Laserjet Printer (Wireless Direct Printing, CZ175A, Black)",price:"20,850"},
      ]
  },
  methods:{
      listProducts:function(){
          this.showscreen=1;
      },
      newProduct:function(){
          this.showscreen=2;
      },
      showDetails:function(prod){
          this.curProduct=prod;
          this.showscreen=3;
      },
      showListScreen:function(){
          this.showscreen=1;
      }
  }
}
</script>
